.paymentmethod-add-button {
    width: 188px !important;
}

.paymentmethod-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.paymentmethod-table {
    border: 1px solid #ced4db;
    border-collapse: separate;
    border-radius: 8px;
    border-spacing: 0;
    margin-bottom: 20px;
    margin-top: 12px;
    width: 100%;
}

.paymentmethod-table td, .paymentmethod-table th {
    border-bottom: 1px solid #ced4db;
    height: 50px;
}

.paymentmethod-table td:first-child, .paymentmethod-table th:first-child {
    padding-left: 28px;
}

.paymentmethod-table td:last-child, .paymentmethod-table th:last-child {
    padding-right: 28px;
}

.paymentmethod-table tr:last-child td {
    border-bottom: none;
}

.paymentmethod-default-wrapper {
    background: #f7f2d7;
    border: 1px solid #f7f2d7;
    border-collapse: separate;
    border-radius: 8px;
    border-spacing: 0;
    padding-left: 10px;
    padding-top: 4px;
    font-size: 14px;
    font-weight: 600;
    width: 98px;
    height: 32px;
}

.paymentmethod-default-check {
    margin-bottom:3px;
    margin-right:6px;
}

.card-logo {
    width: 36px;
    height: 24px;
}

.ach-logo {
    width: 24px;
    height: 24px;
    margin-left: 6px;
}

.paymentmethod-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    font-weight: normal;
    opacity: 1;
}

.paymentmethod-list-link {
    font-size: 13px;
}

.paymentmethod-remove-confirm-modal-size {
    /*width: 80%;
    height: 220px;*/
    max-width: 600px;
}

.paymentmethod-remove-confirm-modal-content {
    /*padding: 28px 32px 0px 33px;*/
}

.paymentmethod-confirm-message-container {
    /*width: 700px;
    margin: 8px 0px 8px 0px;*/
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
    border-radius: 50%;
}

.spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner:before,
.spinner:after {
    position: absolute;
    content: '';
}

.spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
}