input[type='checkbox'] {
    margin-right: 10px;
}

.database-access-table {
    width: 100%;
}

.user-edit-modal-size {
    width: 494px;
    height: 620px;
}

.user-edit-modal-content {
    /*padding: 28px 32px 50px 33px;*/
}
 
.user-edit-field {
    width: 429px;
    font-size: 16px;
}

.user-edit-field-label {
    /*width: 400px;*/
    margin-bottom: 24px;
}

.user-edit-field-label-text {
    font-weight: 700;
    font-size: 14px;
}

.user-edit-checkbox-wrapper {
    width: 50%;
    margin-right:100px;
}

.user-edit-checkbox-label-text {
    font-size: 16px;
    overflow: clip;
    text-overflow: ellipsis;
    width: 250px;
    white-space: nowrap;
}