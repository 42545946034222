.billinghistory-table {
    border: 1px solid #95ccf6;
    border-collapse: separate;
    border-radius: 8px;
    border-spacing: 0;
    margin-bottom: 20px;
    margin-top: 12px;
    width: 100%;
}

.billinghistory-table td, .billinghistory-table th {
    border-bottom: 1px solid #95ccf6;
    height: 50px;
}

.billinghistory-table td:first-child, .billinghistory-table th:first-child {
    padding-left: 28px;
}

.billinghistory-table td:last-child, .billinghistory-table th:last-child {
    padding-right: 28px;
}

.billinghistory-table tr:last-child td {
    border-bottom: none;
}

.billinghistory-list-link {
    font-size: 13px;
}
