/* Provide sufficient contrast against white background */
a {
    color: #4398d9;
    text-decoration: underline;
}

body {
    font-family: 'Segoe UI';

    /*
        https: //stackoverflow.com/questions/16244821/how-to-stop-sticky-footer-from-covering-content
        https://www.w3schools.com/howto/howto_css_fixed_footer.asp
    */
    clear: both;
    min-height: 400px;
    margin-bottom: 87px;
}

code {
  color: #E01A76;
}

h1 {
    font-size: 30px;
    font-weight: 400;
}

h2 {
    font-size: 24px;
    font-weight: 400;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.button-base {
    width: 114px;
    height: 36px;
    background: #4398d9;
    border-radius: 4px;
    border-width: 0px;
    color: #ffffff;
}

.button-base:hover {
    background: #3a84bd;
}

.button-base:active {
    background: #274c77;
    transform: translateY(1px);
}

.container {
    max-width: 1080px;
}

.input-readonly {
    box-sizing: border-box;
    background: rgba(128, 128, 128, 0.08);
    border: 1px solid rgba(183, 183, 183, 0.5);
    border-radius: 2px;
    color: #808080;
    opacity: 0.7;
}

.loading-text {
    font-style: italic;
    font-weight: 500;
    opacity: .75;
    text-align: center;
}

.section-header {
    margin-left: 28px;
}
