.database-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.database-table {
    border: 1px solid #ced4db;
    border-collapse: separate;
    border-radius: 8px;
    border-spacing: 0;
    margin-bottom: 20px;
    margin-top: 12px;
    width: 100%;
}

.database-table td, .database-table th {
    width: 50%;
    height: 36px;
}

.database-table td:first-child, .database-table th:first-child {
    padding-left: 28px;
}

.database-table td:last-child, .database-table th:last-child {
    opacity: .5;
    padding-right: 28px;
    text-align: right;
}

.database-install-link {
    font-size: 13px;
    padding-top: 10px;
    text-align: right;
}

.database-install, .database-install:hover {
    color: #ffffff;
    padding-top: 5px;
    text-align: center;
    text-decoration: none;
    width: 188px !important;
}