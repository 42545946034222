a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.logo {
    max-height: 30px;
    width: auto;
}

.menu-text {
    font-size: 14px;
}

/* Is margin-left and width the best way to center the text in the navbar? */
.navbar-control-panel-text {
    margin-left: 117px;
    opacity: .75;
    text-align: center;
    width: 50%
}
