.billing-header {
    text-align: center;
    margin-bottom: 0px;
    margin-top: 0px;
}

.billing-agency-name {
    font-size: 10px;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 0px;
}

.billing-details-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 14px;
}

.billing-details-section-wrapper {
    padding: 28px;
    border: 1px solid #ced4db;
    border-collapse: separate;
    border-radius: 8px;
    border-spacing: 0;
    margin-bottom: 20px;
    margin-top: 12px;
    width: 100%;
}

.billing-details-section-header-text {
    font-weight: bold;
}

.billing-details-section-text {
    margin-top: 4px;
}

.billing-details-section-subtext {
    margin-top: 12px;
}

.billing-detail-section-semi-transparent {
    opacity: .5;
}

.col1 {
    grid-column-start: 1;
    grid-column-end: 2;
    /*margin-right: 14px;*/
}

.col2 {
    grid-column-start: 2;
    grid-column-end: 3;
    /*margin-left: 14px;*/
}

.billing-contact-change-field {
    width: 463px;
    font-size: 16px;
}

.billing-contact-change-label {
    /*width: 400px;*/
    margin-bottom: 24px;
}

.billing-contact-change-label-text {
    font-weight: 700;
    font-size: 14px;
}