.user-filter-container {
    font-size: 13px;
    padding-top: 8px;
}

.user-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.user-label-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.user-link-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    font-weight: normal;
    opacity: 1;
}
.user-header-text {
    width: 80px;
}

.user-table {
    border: 1px solid #95ccf6;
    border-collapse: separate;
    border-radius: 8px;
    border-spacing: 0;
    margin-bottom: 20px;
    margin-top: 12px;
    width: 100%;
}

.user-table td, .user-table th {
    border-bottom: 1px solid #95ccf6;
    height: 50px;
}

.user-table td:first-child, .user-table th:first-child {
    padding-left: 28px;
}

.user-table td:last-child, .user-table th:last-child {
    padding-right: 28px;
}

.user-table tr:last-child td {
    border-bottom: none;
}

.user-password-change-invalid {
    color: red;
    font-style: italic;
    text-align: center;
}

.user-list-link {
    font-size: 13px;
}

.user-sort-link {
    color: black;
    text-decoration: none;
}

.user-sort-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    margin-left: 4px;
    margin-top: 10px;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
    margin-left: 4px;
    margin-top: 10px;
}

.user-normal {
    font-weight: normal; /* normal = 400 */
}

.user-inactive {
    /*font-weight: 300;*/
    opacity: .5;
}

.user-admin {
    font-weight: 500;
}

.user-active-container {
    display: grid;
    grid-template-columns: auto 1fr
}

.user-active-count-badge {
    background: #4398d9;
    border-radius: 8px;
    border-width: 0px;
    color: #ffffff;
    width: 16px;
    height: 16px;
    padding: 1px;
    text-align: center;
    margin-left: 3px;
    margin-top: 4px;
    font-size: 10px;
    font-weight: 500;
    opacity: 1;
}

.add-user-button {
    width: 188px !important;
}