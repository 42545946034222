.billing-summary-wrapper {
    border-radius: 8px;
    background: #edf6fd;
    height: auto;
    margin-bottom: 20px;
    margin-top: 12px;
    padding: 28px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 32px 22px 22px;
}

.monthly-cost-wrapper {
    display: grid;
    grid-template-columns: auto 1fr
}

.monthly-cost-label {
    padding-left: 2px;
    padding-top: 5px;
}

.col1 {
    grid-column-start: 1;
    grid-column-end: 2;
}

.col2 {
    grid-column-start: 2;
    grid-column-end: 3;
}

.col3 {
    grid-column-start: 3;
    grid-column-end: 4;
}

.right-align {
    text-align: right;
}

.bottom-align {
    margin-top: 8px;
}

/*https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout/Basic_Concepts_of_Grid_Layout*/
.row1 {
    grid-row-start: 1;
    grid-row-end: 2;
}

.row2 {
    grid-row-start: 2;
    grid-row-end: 3;
}

.row3 {
    grid-row-start: 3;
    grid-row-end: 4;
}
