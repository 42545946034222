.login-field {
    width: calc(100% - 100px);
}

.login-field-label {
    margin-bottom: 12px;
    width: 400px;
}

.login-field-label-text {
    display: inline-block;
    width: 100px;
}

.login-form {
    margin-bottom: 10px;
    margin-top: 20px;
}

.login-submit {
    height: 36px;
    width: 100%;
    background: #3a84bd;
    color: #ffffff;
    border-radius: 8px;
    border-width: 0px;
    font-weight: 500;
}

.login-wrapper {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-invalid {
    color: red;
    font-style: italic;
    text-align: center;
}
