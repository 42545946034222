.footer-text {
    font-size: 14px;
    font-weight: 400;
}

.footer-wrapper {
    height: 63px;
    background-color: #18375B;
    color: #ffffff;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-top: 24px;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}